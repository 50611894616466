import '../layouts/tachyons/css/tachyons.css';
import '../layouts/custom.css';
import styled from 'styled-components'

const BtnPrimary = styled.a.attrs({
    className: 'br-pill f5 pointer dib qn-transition-bg',
  })`
  &.medium{
      padding:19px 20px;
  }
  &.small{
      padding:11px 12px;
  }
  &:visited{
      color:#ffffff;
  }
  
  background-color: ${props => props.customBgColor || "#11151E"};
  fill: ${props => props.customFillColor || "#ffffff"};
  `;

const BtnSecondary = styled.a.attrs({
    className: 'br3 f5 pointer dib qn-transition-bg',
  })`
  &.medium{
      padding:12px 24px;
  }
  &.small{
      padding:11px 12px;
  }
  &:link{
    color:#ffffff;
  }
  &:visited{
      color:#ffffff;
  }
  &:hover{
    background-color:#1f283d;
  }
  background-color: ${props => props.customBgColor || "#1b2131"};
  fill: ${props => props.customFillColor || "#fff"};
  `; 

const BtnSecondarySubs = styled.a.attrs({
    className: 'br3 f5 pointer dib qn-transition-bg',
  })`
  &:link{
    color:#ffffff;
  }
  &:visited{
      color:#ffffff;
  }
  &:hover{
    background-color:#1f283d;
  }
  background-color:#1b2131;
  padding:12px 24px;
  `; 

const BtnOutline = styled.a.attrs({
    className: 'br3 f6 inter fw6 pointer dib qn-transition-bg ba1 qn-cl-white',
  })`
  &.medium{
      padding:12px 24px;
  }
  &.small{
      padding:8px 16px;
  }
  &:visited{
      color:#ffffff;
  }
  &:hover{
    background-color:#1f283d;
  }
  background-color: ${props => props.customBgColor || "#1b2131"};
  `; 


export {BtnPrimary, BtnOutline, BtnSecondary, BtnSecondarySubs}
